import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import technolgy from "../../assets/technology-affiliates-bg.png";
import logoone from "../../assets/logos/aws.png";
import logotwo from "../../assets/logos/azure.png";
import logothree from "../../assets/logos/firstDistrubution.png";
import logofour from "../../assets/logos/fujitsu.png";
import logofive from "../../assets/logos/googleCloudPlatformn.png";
import logosix from "../../assets/logos/ibm.png";
import logoseven from "../../assets/logos/iSphere.png";
import logoeight from "../../assets/logos/microsoft.png";
import logonine from "../../assets/logos/tarsus.jpeg";
import "./style.scss";

const TechnologyAffiliates = () => {
  return (
    <div>
      <Header />

      <section className="banner techno mt-0 mb-5">
        <div className="banner-img">
          <div className="col-md-12 ps-0 pe-0">
            <div className="slider border-no">
              <img src={technolgy} alt="technology banner" />
            </div>
          </div>
        </div>
      </section>
      <section className="kt-platform bg-tranh">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title" id="technology-affiliated-center">
                <h1 className="header-color">Technology Affiliates</h1>
              </div>
            </div>
            <div className="row text-container">
              <div className="col-md-12 text-center mb-1 pb-4">
                <h3 className="header-color">
                  Unlock the Power of Collaboration with The KT Platform
                  Technology Affiliates
                </h3>
              </div>
              <div className="col-md-12 text-center mb-1 pb-4 subheader header-color">
                We understand that driving sales and revenue in today's
                competitive landscape can be exceptionally difficult. That's why
                we offer technology affiliate programs that streamline the
                process and helps you connect with clients and maintain strong
                relationships. Here are the key benefits of partnering with us:
              </div>
            </div>

            <div className="row subheader-color">
              <div className="col-md-6 text-start mb-1 pb-4">
                <h3 className="header-color">Simplified Acquisition Process</h3>
                <p>
                  Acquiring leads can be a challenging and time-consuming
                  endeavour. At our <b>Technology Affiliates Center</b>, we
                  simplify the process by integrating your solution and
                  providing clients with an instant direct comparison of the
                  cost associated with using your solution.
                </p>
                <p>
                  Clients can acquire the solution with just a click of a
                  button, enabling efficient and scalable acquisition at scale.
                  We are already connected to a network of clients, and we can
                  also help you engage with specific clients you wish to target.
                </p>
              </div>

              <div className="col-md-6 text-start mb-1 pb-4">
                <h3 className="header-color">
                  Ensuring Client Understanding of Value
                </h3>
                <p>
                  Maintaining existing clients and ensuring they understand the
                  value you deliver can be complex. However, with our secure API
                  integration, we provide a cost breakdown to the client's
                  business at a cost centre level. This includes license
                  touchpoints and cost benefit analysis, ensuring that the
                  solution owner appreciates the constant value you deliver with
                  our technology affiliates services.
                </p>
                <p>
                  By transparently showcasing the tangible benefits and cost
                  effectiveness of your solution, we help strengthen client
                  relationships and reinforce the value you provide.
                </p>
              </div>

              <div className="col-md-6 text-start mb-1 pb-4">
                <h3 className="header-color">
                  Streamlined Process for Affiliates
                </h3>
                <p>
                  As a technology affiliate, our{" "}
                  <b>knowledge transfer platform</b> simplifies your
                  interactions with clients. No longer do you need to go through
                  multiple meetings or perform extensive proof of concept or
                  costing exercises. The KT Platform enables you to seamlessly
                  integrate your solution and swiftly provide clients with the
                  cost comparison they need to make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgone">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">
                  Why Partner with The KT Platform Technology Affiliates?
                </h3>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5">
              <ul className="kt-list subheader-color">
                <li>Access to Leading Technologies</li>
                <li>Enhanced Solution Offerings</li>
                <li>Expertise and Knowledge Sharing</li>
                <li>Tailored and Integrated Solutions</li>
                <li>Strategic Alignment</li>
                <li>Reliable Support and Service</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgtwo">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">
                  Our Technology Affiliate Network
                </h3>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5 subheader-color">
              <ul className="kt-list">
                <li>Leading Cloud Providers</li>
                <li>Innovative Software Vendors</li>
                <li>Security and Compliance Specialists</li>
                <li>Data Analytics and Business Intelligence Partners</li>
                <li>Strategic IT Consulting Firms</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgtwo">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">
                  By eliminating unnecessary barriers, we streamline the
                  acquisition process and empower clients to take action
                </h3>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5">
              <ul className="kt-list">
                <li className="subheader-color">
                  At The KT Platform, we understand the challenges of driving
                  sales and revenue. Through our platform, we aim to make the
                  process more efficient and effective, connecting you with
                  clients and ensuring they appreciate the value you deliver.
                  Join us as a technology affiliate and unlock new opportunities
                  for growth.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="col-md-12 text-center mb-5 pb-4">
        <div className="content-text technology-div">
          <h4
            className="mt-4 pt-2 subheader-color container my-4"
            style={{ fontWeight: 500 }}
          >
            The KT Team is dedicated to bringing suppliers to you. We are
            onboarding new providers daily. Please place a request and we will
            on board your providers free of charge{" "}
          </h4>
          <div className="logo-images">
            <ul style={{ listStyle: "none" }}>
              <li className="icon-expand cursor-pointer">
                <img src={logoone} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logotwo} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logothree} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logofour} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logofive} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logosix} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logoseven} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logoeight} alt="" />
              </li>
              <li className="icon-expand cursor-pointer">
                <img src={logonine} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TechnologyAffiliates;
