import React from "react";
import pricing from "../../assets/pricing-bg.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./style.scss";

const Pricing = () => {
  return (
    <div>
      <Header />

      <section className="banner banner-price mt-0 mb-5">
        <div className="banner-img">
          <div className="col-md-12 ps-0 pe-0">
            <div className="slider border-no">
              <img src={pricing} alt="Pricing banner" />
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h1 className="header-color">Pricing</h1>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5">
              <p className="subheader subheader-color">
                At The KT Platform, we believe in transparent and flexible
                pricing models that cater to the unique needs of our clients.
                Our Multi Cloud hosting plans are designed to provide
                exceptional value while accommodating businesses of all sizes.
                Explore our pricing options below:
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 text-start mb-5 pb-4">
                <div className="content-text">
                  <h4 className="header-color">
                    Monthly subscription for cloud only or the entire business
                    including cloud with unlimited users and reporting.
                  </h4>
                  <ul className="styled-list subheader-color">
                    <li>
                      A detailed drill down view of the business or owned part
                      of the business. With a view of all products.
                    </li>
                    <li>
                      Detailed reports delivered automatically based on your
                      selection to your mailbox.
                    </li>
                    <li>
                      Email alerts with links to the notifications within the
                      platform.
                    </li>
                    <li>
                      Instant alerts of all new Cloud products that are stated
                      up within the current cloud providers connected to KT.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 text-start mb-5 pb-4">
                <div className="content-text">
                  <h4 className="header-color">Manages your spend</h4>
                  <ul className="styled-list subheader-color">
                    <li>Delivery of savings reports.</li>
                    <li>
                      There is an initial and continuous measure and management
                      of spend across all providers.
                    </li>
                    <li>Notifications of increase or decrease in spend.</li>
                    <li>
                      Notifications on renewal or services both on-premise and
                      in the cloud.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-start mb-5 pb-4">
                <div className="content-text">
                  <h4 className="header-color">Simulations</h4>
                  <ul className="styled-list subheader-color">
                    <li>
                      Simulations of proposed new products and services for
                      investment.
                    </li>
                    <li>Simulations of products and services for renewal.</li>
                    <li>
                      Simulations of new products and services being offered by
                      connected providers.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-start mb-5 pb-4">
                <div className="content-text">
                  <h4 className="header-color">
                    Connecting you with partners and Providers
                  </h4>
                  <ul className="styled-list subheader-color">
                    <li>
                      The ability to GET the product or service through The KT
                      Platform.
                    </li>
                    <li>
                      We will connect you with the provider and associated
                      partner of the simulated technology.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgone">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">
                  How We Maximize Efficiency and Financial Benefits for Our
                  Clients?
                </h3>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5">
              <ul className="kt-list">
                <li className="gray--text">
                  Our pricing encompasses the complete transformation of
                  clients' buying systems.
                </li>
                <li className="gray--text">
                  With our Cloud hosting pricing model, clients can benefit from
                  reduced costs and increased efficiency in their buying
                  processes, freeing up valuable resources for other strategic
                  initiatives.
                </li>
                <li className="gray--text">
                  Our pricing includes access to a comprehensive view of
                  clients' cloud infrastructure, Software as a Service (SaaS)
                  applications, and on-premise business operations.
                </li>
                <li className="gray--text">
                  Clients can leverage our pricing structure to simulate new and
                  existing solutions that align with their specific business
                  initiatives.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="kt-platform bgtwo">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <div class="title">
                <h3 className="header-color">
                  How we maximize efficiency and financial benefits for our
                  Suppliers?
                </h3>
              </div>
            </div>
            <div className="col-md-12 text-center mb-5">
              <ul className="kt-list">
                <li className="gray--text">
                  Our pricing model enables suppliers to deliver their solutions
                  directly to the stakeholders who control the budget.
                </li>
                <li className="gray--text">
                  With our pricing structure, suppliers gain the ability to
                  offer their solutions for online purchase.
                </li>
                <li className="gray--text">
                  We provide Cloud hosting pricingoptions that support suppliers
                  in driving sales within their existing client base through
                  upgrades and refreshes.
                </li>
                <li className="gray--text">
                  Our Cloud hosting packages offer suppliers exposure in new
                  business domains where they may currently have no presence.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Pricing;
